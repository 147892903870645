//rafce
import React from 'react'

import { About, Footer, Header } from '../container'


const Home = () => {
  return (
    <div>
        <Header />
        <About />
        <Footer />
    </div>
  )
}

export default Home
