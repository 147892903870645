import React from 'react';
import { motion } from 'framer-motion';

import { images } from '../../constants';
import './Header.scss';

const scaleVariants = {
  whileInView: {
    scale: [0, 1],
    opacity: [0, 1],
    transition: {
      duration: 1,
      ease: 'easeInOut',
    },
  },
};

const Header = () => (
  <div className="app__header app__flex">

    <motion.div
      whileInView={{ opacity: [0, 1] }}
      transition={{ duration: 0.5, delayChildren: 0.5 }} //delays the following elements
      className="app__header-img"
    >
      <img src={images.cover_photo} alt="cover_photo" />
    </motion.div>

    <motion.div
      whileInView={{ x: [0, 0], opacity: [0, 1] }}
      transition={{ duration: 0.5 }}
      className="app__header-info"
    >
      {/* <div className="app__header-title">
            <div className="head-text">
              Photos for all seasons. 
              Photos for all seasons.
              Photos for all seasons.
              Photos for all seasons.
              Photos for all seasons.
              Photos for all seasons.
              Photos for all seasons.
              Photos for all seasons.
            </div>
      </div> */}

    {/* <div class="m-scroll">
      <div class="m-scroll__title">
        <div>
          <p className='head-text'>
          photos for all seasons &nbsp;
          </p>
          <p className='head-text'>
          photos for all seasons &nbsp;
          </p>
          <p className='head-text'>
          photos for all seasons &nbsp;
          </p>
          <p className='head-text'>
          photos for all seasons final &nbsp;
          </p>
        </div>
      </div>
    </div> */}
    <div className="scrolling-container">
      <div className="head-text">
        photos for all seasons
      </div><div className="head-text" aria-hidden="true">
      photos for all seasons
      </div><div className="head-text" aria-hidden="true">
      photos for all seasons
      </div><div className="head-text" aria-hidden="true">
      photos for all seasons
      </div>
      <div className="head-text">
        photos for all seasons
      </div><div className="head-text" aria-hidden="true">
      photos for all seasons
      </div><div className="head-text" aria-hidden="true">
      photos for all seasons
      </div><div className="head-text" aria-hidden="true">
      photos for all seasons
      </div>
    </div>
    </motion.div>

  </div>
);

export default Header
