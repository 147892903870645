//rafce
import React from 'react'

import { Navbar } from './components'

import './App.scss'

import {
      BrowserRouter as Router,
      Routes,
      Route,
  } from "react-router-dom";
  import Home from "./pages";
  import Newsletters from "./pages/Newsletters";
  import Film from "./pages/Film";
  import Digital from "./pages/Digital";



function App() {
  return (
    <div className='app'>

      <Router>
          <Navbar />
          <Routes>
              <Route exact path="/" element={<Home />} />
              <Route path="/Newsletters" element={<Newsletters />} />
              <Route path="/Digital" element={<Digital />} />
              <Route path="/Film" element={<Film />} />
          </Routes>
      </Router>

      </div>

  );
}

export default App
