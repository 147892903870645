import React from 'react'
import { Footer, NewsletterGallery } from '../container'


const Newsletters = () => {
  return (
    <div>
      <NewsletterGallery/>  
      <Footer />
    </div>
  )
}

export default Newsletters