import React, { useState, useEffect, useCallback } from "react";
import { render } from "react-dom";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import { useAnimate, motion } from 'framer-motion';
import { urlFor, client } from '../../client';
import {getImageDimensions} from '@sanity/asset-utils'

import './DigitalGallery.scss';


const DigitalGallery = () => {

  //sanity.io client
  const [photos, setPhotos] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  
  const [filters, setFilters] = useState([]);
  const all_tags = [...new Set (photos.map(photo => photo.tags).flat(1))]

  useEffect(() => {
    const photo_query = '*[_type == "digitalPhotos"]';

    client.fetch(photo_query).then((data) => {
      setPhotos(data);
      setIsLoading(false);
    })
  }, []);

  const filteredImages = photos.filter(filterPhotos)
    .map(photo => ({
      src: urlFor(photo.imgUrl),
      width: getImageDimensions({url: urlFor(photo.imgUrl).toString()}).aspectRatio, 
      height: 1,
      name: photo.name, 
      description: ("Captured " + photo.date + " in " + photo.location)
    }));

  // gallery view
  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  function filterPhotos(photo) {
    if (filters.length == 0) { return true }
    if (photo.tags) {
      for (let i = 0; i < filters.length; i++) {
        if (photo.tags.includes(filters[i])) {
          return true
        }
      } 
    } else { return false }
  }

  function toggleFilter(event) {
    const new_filter = event.target.id
    const new_filter_list = Array.apply(null, filters)
    if (filters.includes(new_filter)) {
      const index = filters.indexOf(new_filter)
      new_filter_list.splice(index, 1)
      event.target.className = "btn"

    } else {
      new_filter_list.push(new_filter)
      event.target.className += " active"
    }
    setFilters(new_filter_list)
  }

  // loading animation 
  const text = "PHOTOS LOADING...";
  const characters = text.split("");

  const radius = 80;
  const fontSize = "22px";
  const letterSpacing = 18.5;

  const [scope, animate] = useAnimate();

  useEffect(() => {
    const animateLoader = async () => {
      const letterAnimation = [];
      characters.forEach((_, i) => {
        letterAnimation.push([
          `.letter-${i}`,
          { opacity: 0 },
          { duration: 0.3, at: i === 0 ? "+0.8" : "-0.28" }
        ]);
      });
      characters.forEach((_, i) => {
        letterAnimation.push([
          `.letter-${i}`,
          { opacity: 1 },
          { duration: 0.3, at: i === 0 ? "+0.8" : "-0.28" }
        ]);
      });
      animate(letterAnimation, {
        ease: "linear",
        repeat: Infinity
      });
      animate(
        scope.current,
        { rotate: 360 },
        { duration: 4, ease: "linear", repeat: Infinity }
      );
    };
    animateLoader();
  }, []);

  return (
    <div className="app__digitalgallery app__flex">

    <motion.div
      whileInView={{ x: [0, 0], opacity: [0, 1] }}
      transition={{ duration: 0.5 }}
      className="app__header-info"
    >
      <div className="app__digitalgallery-header">
            <div className="head-text">
              Digital Photos
            </div>
      </div>
    </motion.div>

    <motion.div
      whileInView={{ x: [0, 0], opacity: [0, 1] }}
      transition={{ duration: 0.5 }}
      className="app__photos-filters"
    >
      <p className="bold-text"> Want to filter by location? </p>
      <div className="btnContainer">
        {all_tags.filter(function(tag) {
          return !!tag
        }).map((tag) => (
            <button class="btn" id={tag} onClick={toggleFilter}>{tag}</button>
        ))}
      </div>
    </motion.div>

    <div className="app__photos">

    {isLoading ? (
         <motion.div ref={scope} className="loading_circle" style={{ width: radius * 2 }} id="loading_circle">
         <p aria-label={text} />
         <p aria-hidden="true" className="text">
           {characters.map((ch, i) => (
             <motion.span
               key={i}
               className={`letter letter-${i}`}
               style={{
                 transformOrigin: `0 ${radius}px`,
                 transform: `rotate(${i * letterSpacing}deg)`,
                 fontSize
               }}
             >
               {ch}
             </motion.span>
           ))}
         </p>
        </motion.div>
      ) : (
        <div>
          <Gallery photos={filteredImages} onClick={openLightbox}/>
          <p className="p-text"> {filteredImages.length} images captured</p>
          <ModalGateway>
          {viewerIsOpen ? (
            <Modal onClose={closeLightbox}>
              <Carousel
                currentIndex={currentImage}
                views={filteredImages.map(img => ({
                  src: img.src.toString(),
                  caption: "<h2>" + img.name + "</h2>" + img.description
                }))}
              />
            </Modal>
          ) : null}
        </ModalGateway>
        </div>
      )}
     </div>

    </div>
  )
}

export default DigitalGallery