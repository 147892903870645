// actually used
import logo_full from '../assets/logo_full.png';
import logo_name from '../assets/logo_name.png';
import line from '../assets/line.png';
import cover_photo from '../assets/cover_photo.jpeg';
import logo from '../assets/logo.png';
// import email from '../assets/email.svg';

export default {
    //actually used
    logo_full, 
    logo_name,
    line,
    cover_photo,
    logo,
    // email
};