import React from 'react'
import { Footer, FilmGallery } from '../container'


const Film = () => {
  return (
    <div>
      <FilmGallery/>  
      <Footer />
    </div>
  )
}

export default Film