import React from 'react'
import { Footer, DigitalGallery } from '../container'


const Digital = () => {
  return (
    <div>
      <DigitalGallery/>  
      <Footer />
    </div>
  )
}

export default Digital