import React, { useState, useEffect } from 'react';
import { motion, useAnimate } from "framer-motion";
// import { useEffect } from "react";

import { images } from '../../constants';
import { client } from '../../client';
import './Footer.scss';

const Footer = () => {
  const [formData, setFormData] = useState({ name: '', email: '', message: '' });
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  const { username, email, message } = formData;

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = () => {
    setLoading(true);

    const contact = {
      _type: 'contact',
      name: formData.username,
      email: formData.email,
      message: formData.message,
    };

    client.create(contact)
      .then(() => {
        setLoading(false);
        setIsFormSubmitted(true);
        document.getElementById('loading_circle').style.display = 'block'
      })
      .catch((err) => console.log(err));
  };

  // loading animation 
    const text = "COFFEE INVITE PENDING...";
    const characters = text.split("");
  
    const radius = 60;
    const fontSize = "12px";
    const letterSpacing = 10.5;
  
    const [scope, animate] = useAnimate();
  
    useEffect(() => {
      const animateLoader = async () => {
        const letterAnimation = [];
        characters.forEach((_, i) => {
          letterAnimation.push([
            `.letter-${i}`,
            { opacity: 0 },
            { duration: 0.3, at: i === 0 ? "+0.8" : "-0.28" }
          ]);
        });
        characters.forEach((_, i) => {
          letterAnimation.push([
            `.letter-${i}`,
            { opacity: 1 },
            { duration: 0.3, at: i === 0 ? "+0.8" : "-0.28" }
          ]);
        });
        animate(letterAnimation, {
          ease: "linear",
          repeat: Infinity
        });
        animate(
          scope.current,
          { rotate: 360 },
          { duration: 4, ease: "linear", repeat: Infinity }
        );
      };
      animateLoader();
    }, []);

  return (
    <div className="app__footer app__flex">
      <div className='app__footer-header'> 
        <h2 className="head-text">Want to grab a coffee?</h2>
        <p className="p-text">(I'll take any excuse)</p>
      </div>
      
        {!isFormSubmitted ? (
          <div className="app__footer-form app__flex">
            <div className="app__flex">
              <input className="p-text" type="text" placeholder="Your Name" name="username" value={username} onChange={handleChangeInput} required/>
            </div>
            <div className="app__flex">
              <input className="p-text" type="email" placeholder="Your Email" name="email" value={email} onChange={handleChangeInput} required/>
            </div>
            <div>
              <textarea
                className="p-text"
                placeholder="Your Message"
                value={message}
                name="message"
                onChange={handleChangeInput}
                required
              />
            </div>
            <button type="button" className="p-text" onClick={handleSubmit}>{!loading ? 'Send Message' : 'Sending...'}</button>
          </div>
        ) : (
          <div></div>
        )}

        <motion.div ref={scope} className="loading_circle" style={{ width: radius * 2 }} id="loading_circle">
            <p aria-label={text} />
            <p aria-hidden="true" className="text">
              {characters.map((ch, i) => (
                <motion.span
                  key={i}
                  className={`letter letter-${i}`}
                  style={{
                    transformOrigin: `0 ${radius}px`,
                    transform: `rotate(${i * letterSpacing}deg)`,
                    fontSize
                  }}
                >
                  {ch}
                </motion.span>
              ))}
            </p>
        </motion.div>


  <div className="app__footer-message">
    <p class="p-text">I am not currently on social media, but you can always <a href="mailto:kms.summers@gmail.com" class="p-text">shoot me an email</a> or <a href="tel:+1 (401) 680-0854" class="p-text">give me a call</a>!</p>
    <img src={images.logo_full} alt="full logo" />

    <div className="app__footer-fine-print">
      <p className="p-text">
      ©2024 Summers Photography | Designed and built by Katie Summers
      </p>
    </div>
    </div>
    
  </div>
  );
};

export default Footer