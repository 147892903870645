import React, {useState} from 'react'
import { HiMenuAlt4, HiX } from 'react-icons/hi';
import { motion } from 'framer-motion';

import './Navbar.scss'
import {images} from '../../constants'
import { NavLink } from 'react-router-dom';
 
const Navbar = () => {
  const [toggle, setToggle] = useState(false);


  return (
    <nav className="app__navbar">
      <div className="app__navbar-logo">
        <img src={images.line} alt="line"/>
          <NavLink to="/" > 
            <img src={images.logo_name} alt="logo" id="logo-name"/>
          </NavLink>
        <img src={images.line} alt="logo"/>
      </div>

      <ul className="app__navbar-links" >
        {['Digital', 'Film', 'Newsletters'].map((item) => (
          <li >
            <div/>
            <NavLink className="app__flex p-text" to={`/${item}`}>
              {item}
            </NavLink>
          </li>
        ))}
      </ul>

      <div className="app__navbar-menu">
        <HiMenuAlt4 onClick={() => setToggle(true)} />

        {toggle && (
          <motion.div
            whileInView={{ x: [300, 0] }}
            transition={{ duration: 0.85, ease: 'easeOut' }}
          >
            <HiX onClick={() => setToggle(false)} />
            <ul>
                <li >
                  <NavLink className="app__flex p-text" to='/'>
                      Home
                  </NavLink>
                </li>
              {['Digital', 'Film', 'Newsletters'].map((item) => (
                <li >
                  <NavLink className="app__flex p-text" to={`/${item}`}>
                      {item}
                  </NavLink>
                </li>
              ))}
            </ul>
          </motion.div>
        )}
      </div>


    </nav>
  )
}

export default Navbar