import React, { useState, useEffect, useCallback } from 'react';
import { motion, useAnimate } from 'framer-motion';
import Carousel, { Modal, ModalGateway } from "react-images";

import { images } from '../../constants';
import './NewsletterGallery.scss';
import { urlFor, client } from '../../client';


const NewsletterGallery = () => {

  const [newsletters, setNewsletters] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const query = '*[_type == "newsletters"]';

    client.fetch(query).then((data) => {
      setNewsletters(data);
      setIsLoading(false);
    });
  }, []);


  // gallery view
  const [currentIssue, setCurrentIssue] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event) => {
    const issueNo = parseInt(event.target.alt)
    const issue = newsletters.filter(newsletter => newsletter.issue == issueNo)
    setCurrentIssue(issue);
    setViewerIsOpen(true);
  }, [newsletters]);

  const closeLightbox = () => {
    setCurrentIssue(0);
    setViewerIsOpen(false);
  };

  // loading animation 
  const text = "NEWSLETTERS LOADING...";
  const characters = text.split("");

  const radius = 80;
  const fontSize = "20px";
  const letterSpacing = 16;

  const [scope, animate] = useAnimate();

  useEffect(() => {
    const animateLoader = async () => {
      const letterAnimation = [];
      characters.forEach((_, i) => {
        letterAnimation.push([
          `.letter-${i}`,
          { opacity: 0 },
          { duration: 0.3, at: i === 0 ? "+0.8" : "-0.28" }
        ]);
      });
      characters.forEach((_, i) => {
        letterAnimation.push([
          `.letter-${i}`,
          { opacity: 1 },
          { duration: 0.3, at: i === 0 ? "+0.8" : "-0.28" }
        ]);
      });
      animate(letterAnimation, {
        ease: "linear",
        repeat: Infinity
      });
      animate(
        scope.current,
        { rotate: 360 },
        { duration: 4, ease: "linear", repeat: Infinity }
      );
    };
    animateLoader();
  }, []);

  return (
    <div className="app__newslettergallery app__flex">

    <motion.div
    // this moves everything in slowly on load
      whileInView={{ x: [0, 0], opacity: [0, 1] }}
      transition={{ duration: 0.5 }}
      className="app__header-info"
    >
      <div className="app__newslettergallery-header">
            <div className="head-text">
              Newsletters
            </div>
      </div>
    </motion.div>

    <motion.div
      whileInView={{ x: [0, 0], opacity: [0, 1]}}
      transition={{ duration: 0.5 }}
      className="app__issues-summary"
    >
        <h2 className='bold-text'>
        Archives from South America  
        </h2>
        <p className='p-text'>
        In February 2023, I quit my job and left on a one-way flight to Patagonia.
        For 10 months, I spent all my time rock climbing, camping, and working odd jobs, 
        traveling north along the Andes. I fed sled dogs in Argentina, hitchhiked across 
        the border of Chile, slept in the salt flats of Bolivia, thru-hiked in Peru, and 
        swam in every body of water I came across. 
        </p>
        <p className='p-text'>
        Having no social media, I decided to share my stories on my own terms.
        Throughout my trip, I emailed close friends and family these newsletters.
        </p>
    </motion.div>


      {isLoading ? (
        <motion.div ref={scope} className="loading_circle" style={{ width: radius * 2 }} id="loading_circle">
        <p aria-label={text} />
        <p aria-hidden="true" className="text">
          {characters.map((ch, i) => (
            <motion.span
              key={i}
              className={`letter letter-${i}`}
              style={{
                transformOrigin: `0 ${radius}px`,
                transform: `rotate(${i * letterSpacing}deg)`,
                fontSize
              }}
            >
              {ch}
            </motion.span>
          ))}
        </p>
       </motion.div>
      ) : (
        <div className="app__issues-covers">
          {newsletters.filter(newsletter => newsletter.page == 1)
            .sort((a, b) => {
              return a.issue - b.issue;
            })
            .map((newsletter, index) => (
              <motion.div
                whileInView={{ opacity: [0,1] }}
                whileHover={{ scale: 1.1 }}
                transition={{ duration: 0.75, type: 'tween', delayChildren: 0.5}}
                key={newsletter.issue + index}
                className="app__issues-item"
                onClick={openLightbox}
              >
                <img src={urlFor(newsletter.imgUrl).toString()} alt={newsletter.issue}/>
                <h2 className="bold-text" style={{ marginTop: 20 }}>Issue {newsletter.issue}</h2>

              </motion.div>
            ))} 

            <ModalGateway>
            {viewerIsOpen ? (
                <Modal onClose={closeLightbox}>
                  <Carousel
                    currentIndex="0"
                    views={currentIssue.sort((a, b) => {
                      return a.page - b.page;
                    })
                      .map(page => ({
                      src: urlFor(page.imgUrl).toString(),
                      caption: page.title
                    }))}
                  />
                </Modal>
              ) : null}
            </ModalGateway>
            
        </div>

        
      )}

    </div>
  )
}

export default NewsletterGallery